// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefits-js": () => import("./../../../src/pages/benefits.js" /* webpackChunkName: "component---src-pages-benefits-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-about-us-js": () => import("./../../../src/pages/company/about-us.js" /* webpackChunkName: "component---src-pages-company-about-us-js" */),
  "component---src-pages-company-case-studies-jsx": () => import("./../../../src/pages/company/case-studies.jsx" /* webpackChunkName: "component---src-pages-company-case-studies-jsx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../../../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-products-artificial-intelligence-js": () => import("./../../../src/pages/products/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-products-artificial-intelligence-js" */),
  "component---src-pages-products-cv-parsing-demo-js": () => import("./../../../src/pages/products/cv-parsing/demo.js" /* webpackChunkName: "component---src-pages-products-cv-parsing-demo-js" */),
  "component---src-pages-products-cv-parsing-js": () => import("./../../../src/pages/products/cv-parsing.js" /* webpackChunkName: "component---src-pages-products-cv-parsing-js" */),
  "component---src-pages-products-cv-parsing-packages-js": () => import("./../../../src/pages/products/cv-parsing/packages.js" /* webpackChunkName: "component---src-pages-products-cv-parsing-packages-js" */),
  "component---src-pages-products-voice-model-implementation-js": () => import("./../../../src/pages/products/voice-model-implementation.js" /* webpackChunkName: "component---src-pages-products-voice-model-implementation-js" */),
  "component---src-pages-services-artificial-intelligence-consulting-js": () => import("./../../../src/pages/services/artificial-intelligence-consulting.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-consulting-js" */),
  "component---src-pages-services-artificial-intelligence-js": () => import("./../../../src/pages/services/artificial-intelligence.js" /* webpackChunkName: "component---src-pages-services-artificial-intelligence-js" */),
  "component---src-pages-services-business-intelligence-js": () => import("./../../../src/pages/services/business-intelligence.js" /* webpackChunkName: "component---src-pages-services-business-intelligence-js" */),
  "component---src-pages-services-data-engineering-js": () => import("./../../../src/pages/services/data-engineering.js" /* webpackChunkName: "component---src-pages-services-data-engineering-js" */),
  "component---src-pages-services-data-science-and-analytics-js": () => import("./../../../src/pages/services/data-science-and-analytics.js" /* webpackChunkName: "component---src-pages-services-data-science-and-analytics-js" */),
  "component---src-pages-services-generative-ai-consulting-js": () => import("./../../../src/pages/services/generative-ai-consulting.js" /* webpackChunkName: "component---src-pages-services-generative-ai-consulting-js" */),
  "component---src-pages-services-mlops-js": () => import("./../../../src/pages/services/mlops.js" /* webpackChunkName: "component---src-pages-services-mlops-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-career-detail-js": () => import("./../../../src/templates/careerDetail.js" /* webpackChunkName: "component---src-templates-career-detail-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/caseStudies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-list-blog-js": () => import("./../../../src/templates/listBlog.js" /* webpackChunkName: "component---src-templates-list-blog-js" */)
}

